@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Roboto', sans-serif;
  }
}

#root {
  overflow-x: hidden;
  overflow-y: auto;
}

select {
  background-position: right 0.125rem center;
  background-size: 1.25em 1.25em;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
}

label {
  font-size: 13px;
  margin-bottom: 0.125rem;
  align-self: center;
}

.react-datepicker-wrapper
  .react-datepicker__input-container
  input[type='text'] {
  background-image: url(../img/icon/icon_calendar.png);
  background-repeat: no-repeat;
  background-position: right 5px center;
}

a.editable-text:not([href]) {
  color: #007bff;
  cursor: pointer;
}

a.editable-text:not([href]):hover {
  text-decoration: underline;
}

@keyframes blink {
  0% {
    background: none;
  }

  25% {
    background: #ffc0cb;
  }

  50% {
    background: none;
  }

  75% {
    background: #ffc0cb;
  }

  100% {
    background: none;
  }
}

.blink {
  -webkit-animation-direction: normal;
  -webkit-animation-duration: 3.5s;
  -webkit-animation-iteration-count: 8;
  -webkit-animation-name: blink;
  -webkit-animation-timing-function: ease;
}

.text-underline {
  text-decoration: underline !important;
}

.popover-header {
  padding: 0.25rem 0.5rem;
  font-size: 13px;
}

.popover-body {
  padding: 0.25rem 0.5rem;
}

/**------------------------------------------------------------
* table
---------------------------------------------------------------*/

.table {
  margin-bottom: 0;
  border-collapse: collapse;
}

.table th,
.table thead th {
  vertical-align: middle;
  letter-spacing: 0.5px;
}

.table td,
.table th {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 12px;
  vertical-align: middle;
  word-break: break-word;
}

.table tfoot td {
  font-weight: 600;
  background: var(--color-text-base) !important;
}

.table-nobordered th,
.table-bordered th {
  background: var(--color-bg-tbl-th);
  color: var(--color-text-4);
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;
  white-space: nowrap;
}

.table.table-bordered td,
.table.table-bordered th {
  border: 0.5px solid var(--color-border-tbl);
}

.table.table-bordered tr:first-of-type > td {
  border-top: none;
}

.table.table-bordered th {
  padding: 6px 8px;
  height: 32px;
}

.table.table-bordered td {
  padding: 0px 4px;
  height: 28px;
}

.table-bordered tr.total td {
  background: #f5f5f5;
  color: #fe5353;
  font-weight: 500;
  font-size: 14px;
}

.table-nobordered tr td,
.table-bordered tr td {
  background: var(--color-bg-tbl-td);
}

.table-nobordered tr:nth-child(even) td,
.table-bordered tr:nth-child(even) td {
  background: var(--color-bg-tbl-even);
}

.table-nobordered tr td,
.table-nobordered tr th {
  padding: 4.8px;
}

.table-bordered .bg-active td {
  background-color: var(--color-bg-primary) !important;
}

.table-bordered .bg-active td.high-light {
  background-color: var(--color-bg-primary) !important;
}

.table-bordered tr:nth-child(even) td.high-light,
.table-bordered tr:nth-child(odd) td.high-light,
.table-bordered tr th.high-light {
  background: var(--color-bg-tbl-light);
}

.table-bordered path {
  fill: var(--color-img-tbl);
}

.tbl-sub {
  font-size: 11px;
}

@media (max-width: 450px) {
  .table td,
  .table th {
    padding: 2px;
    font-size: 12px;
  }

  .tbl-sub {
    font-size: 8px;
  }
}

/**------------------------------------------------------------
* color
---------------------------------------------------------------*/
.i,
.green {
  color: var(--color-text-up) !important;
}

.auth-messages,
.d,
.red {
  color: var(--color-text-down) !important;
}

.e,
.r,
.yellow {
  color: var(--color-text-ref) !important;
}

.f,
.violet {
  color: var(--color-text-floor) !important;
}

.c,
.violet {
  color: var(--color-text-ceil) !important;
}

.d.active,
.red.active {
  background: var(--color-text-down) !important;
  color: #ffffff !important;
}

.i.active {
  background: var(--color-text-up) !important;
  color: #ffffff !important;
}

.r.active,
.e.active {
  background: var(--color-text-ref) !important;
  color: #ffffff !important;
}

.f.active {
  background: var(--color-text-floor) !important;
  color: #ffffff !important;
}

.c.active {
  background: var(--color-text-ceil) !important;
  color: #ffffff !important;
}

.lot.active {
  background: var(--color-bg-total) !important;
  color: #ffffff !important;
}

.index {
  color: var(--color-text-3) !important;
}

.unit {
  color: var(--color-text-unit) !important;
}

/**------------------------------------------------------------
* header
---------------------------------------------------------------*/
/* .form-control {
  padding: 5px 8px;
  border-color: rgb(198, 203, 210);
} */

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-analysis:focus {
  box-shadow: 0 0 0 0.2rem var(--color-natural-3);
}

.app-header {
  font-size: 13px;
  font-weight: normal;
  color: var(--color-text-3);
  background: var(--color-bg-header);
}

.app-header.sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.app-header .nav-button {
  background-color: #f3f3f3;
  box-shadow: -1px 0px 3px 0px #00000040 inset;
  border-radius: 4px;
  padding: 1px;
  color: #575758;
}

.app-header .nav-button .active {
  background: linear-gradient(172.44deg, #0F6CBD 11.52%, #0F6CBD 81.46%);
  box-shadow: 4px 0px 3px 0px #00000033;
  border-radius: 4px;
  font-weight: 600;
  color: #ffffff;
}

.app-header svg path {
  fill: var(--color-img-header);
}

.app-header svg.radio path:first-child {
  fill: var(--color-radio-header);
}

.app-header svg.radio.uncheck path:last-child {
  fill: #00923f;
}

.app-header .btn-login {
  background-color: var(--color-bg-btn-login);
  color: var(--color-text-btn-login);
}

.dropdown {
  position: relative;
}

.dropdown .bg-skin-dropdown.absolute {
  box-shadow: 0px -1px 9.4px 0px #00000059;
}

.dropdown-menu.show {
  position: absolute;
  top: 36px;
  z-index: 1;
  min-width: 145px;
  max-width: 150px;
  background-color: #ffffff;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 2px;
}

.dropdown-menu.show ul {
  padding-inline-start: 0;
  font-size: 13px;
}

.dropdown-menu.show ul li {
  padding: 5px 10px;
  cursor: pointer;
  color: #000;
}

.dropdown-menu.show ul li:hover {
  background: #f3f9ff;
}

.dropdown-menu.show ul li.active {
  background: #4d7496;
  color: #fff;
}

/**------------------------------------------------------------
* body
---------------------------------------------------------------*/
.app-body {
  width: 100%;
  display: -webkit-box;
  color: var(--color-text-base);
}

main.main {
  display: flex;
  flex-direction: column;
  position: relative;
}

main.main.nav_minimize {
  width: calc(100vw - 98px);
}

.card {
  box-shadow: 0 4px 12px -4px rgba(0, 0, 0, 0.19);
  border: none;
}

.card .card-header {
  background: transparent;
  border-color: #e8eaeb;
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  font-size: 14px;
}

.card .card-header svg path {
  stroke: #ffa31a;
}

.card .card-body {
  padding: 0.5rem;
  font-size: 14px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.btn-search {
  width: 120px;
  height: 28px;
}

.tbl-group-button {
  gap: 5px;
}

.tbl-group-button a {
  background: #f3f3f3;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tbl-group-button a svg {
  max-height: 13px;
  max-width: 13px;
}

.tbl-group-button a svg path {
  stroke: var(--color-bg-primary);
}

.custom-ui-confirm h1 {
  font-size: 35px;
  font-weight: 500;
}

.upload-file {
  position: relative;
}

.upload-file .file {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}

.btn {
  display: inline-flex;
  justify-content: center;
  column-gap: 7px;
  align-items: center;
  border-radius: 6px;
  font-weight: 500;
}

.btn i {
  font-size: 16px;
}

/**------------------------------------------------------------
*!perfect scrollbar
---------------------------------------------------------------*/

.ps__rail-x {
  height: 6px;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: var(--color-thumb) !important;
  height: 6px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-x.ps--clicking {
  background-color: var(--color-thumb) !important;
  opacity: 0.9;
  height: 6px;
}

.ps__thumb-x {
  height: 6px;
  bottom: 0;
}

.ps__thumb-y,
.ps__rail-y {
  width: 0px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--color-thumb) !important;
  width: 6px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
  background-color: var(--color-thumb) !important;
  opacity: 0.9;
  width: 6px;
}

.panel-depth-market {
  border-top: 1px solid #020810;
  max-height: 396px;
}

.panel-depth-market ul li {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 4px;
  padding: 6px 8px;
}

.panel-asset-footer ul {
  border: 1px solid var(--color-natural-1);
}

.panel-asset-footer ul li {
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
}

.panel-asset-footer ul li.tbl-header {
  background-color: var(--color-natural-1);
}

.panel-asset-footer ul li:nth-child(odd):not(.tbl-header),
.panel-depth-market ul li:nth-child(odd):not(.tbl-header) {
  background-color: var(--color-bg-li-light);
}

.panel-depth-market ul li span {
  align-self: center;
}

.progress-bar {
  display: flex;
}

.progress-bar.has-unknown {
  background-color: var(--color-border);
}

.progress-bar .step-1 {
  border-radius: 2px 0 0 2px;
}

.progress-bar .step-2 {
  border-radius: 0 2px 2px 0;
}

.select-list-trading li {
  padding: 8px 0;
  font-size: small;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.select-list-trading li:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border-3);
}

.select-list-trading li.selected {
  color: var(--color-vendor-from);
}

.text-input-trading {
  box-shadow: none;
  outline: none;
}

.text-input-trading:active,
.text-input-trading:focus {
  box-shadow: none;
  outline: none;
}

.text-skin-active svg path {
  fill: var(--color-text-6);
}

svg.text-skin-placeholder path {
  fill: var(--color-thumb);
}

.cl-border-var {
  border-color: var(--color-border);
}

ul.list-timeline li {
  position: relative;
}

ul.list-timeline li:not(:first-of-type)::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 10px;
  width: 1px;
  height: 40px;
  background-color: var(--color-natural-4);
}

/* index-chart */

.green.bg-active,
.i.bg-active {
  background: var(--color-text-up) !important;
  color: var(--color-text-base) !important;
}

.red.bg-active,
.d.bg-active {
  background: var(--color-text-down) !important;
  color: var(--color-text-base) !important;
}

.r.bg-active {
  background: var(--color-text-ref) !important;
  color: var(--color-text-base) !important;
}

.form-add {
  width: 100%;
  height: 26px;
  padding: 5px 8px;
  border-radius: 2px;
  background-color: var(--color-bg-body);
  color: var(--color-text-nav);
  text-transform: uppercase;
  border: 0.5px solid var(--color-bd-nav-active);
  box-sizing: border-box;
}

.form-add:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-add::placeholder,
.form-search .form-control::placeholder {
  font-size: 12px;
  color: var(--color-text-nav);
  text-transform: initial !important;
}

/* price-table */
.slidable .active {
  background: var(--color-bg-total) !important;
  color: #ffffff !important;
}

table tr td > .slidable,
table tr th > .slidable {
  box-sizing: border-box;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

table tr td > .slidable > div.cell-1-2,
table tr th > .slidable > div.cell-1-2 {
  width: 50%;
  height: 100%;
  padding: 5px;
  position: relative;
}

table tr td > .slidable > div.cell-1-1,
table tr th > .slidable > div.cell-1-1 {
  width: 100%;
  padding: 5px;
}

table tr td > .slidable > div:nth-child(2),
table tr th > .slidable > div:nth-child(2) {
  border-left: 0.5px solid var(--color-border-tbl);
}

.table tr td .act-del {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  cursor: pointer;
  width: 15px;
  height: 12px;
}

.table tr:hover td .act-del {
  display: block;
}

.icon-sort-header {
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.tbl-toggle-left {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  color: var(--color-img-tbl);
}

.tbl-toggle-right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  color: var(--color-img-tbl);
}

/**------------------------------------------------------------
* stock detail
---------------------------------------------------------------*/
span.mc {
  width: 50px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #999999;
}

.detail-search .form-control {
  width: 120px;
  height: 26px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #f3f3f3;
  border: solid 1px #f2f4f6;
  padding-right: 25px;
}

.table .rtl .progress {
  justify-content: flex-end;
}

.progress-bar {
  border-radius: 2px;
}

.table .progress .bg-info {
  background: #60a8ea !important;
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-danger,
.table .progress .bg-d {
  background: rgba(240, 161, 182, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-r {
  background: rgba(120, 112, 41, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-c {
  background: rgba(120, 64, 152, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-f {
  background: rgba(10, 131, 138, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-i {
  background: rgba(88, 160, 112, 0.3);
  border-radius: 1px;
  height: 100%;
}

ul.list-price-info li {
  padding: 2px 5px;
  border-bottom: 0.5px solid #cccccc;
}

.tab-pane {
  width: 100%;
  height: 100%;
}

.table .progress {
  background-color: transparent;
  padding: 2px 0;
  height: 22px;
  border-radius: 0;
}

.table td.rtl .title-row {
  left: 3px;
  position: absolute;
  top: 2px;
  padding: 2px 5px;
  background: #dddddd;
  border-radius: 2px;
  width: 16.75px;
  text-align: center;
}

.table .rtl .progress-bar {
  margin-left: auto;
}

.table td.rtl .per-vol {
  position: absolute;
  font-size: 12px;
  top: 4px;
  left: 0.75rem;
}

.table td.rtl .per-price {
  position: absolute;
  font-size: 12px;
  right: 8px;
  top: 4px;
}

.table td.rtr .title-row {
  right: 3px;
  position: absolute;
  top: 2px;
  padding: 2px 5px;
  background: #dddddd;
  border-radius: 2px;
  width: 16.75px;
  text-align: center;
}

.table td.rtr .per-vol {
  position: absolute;
  font-size: 12px;
  top: 4px;
  right: 22px;
}

.table td.rtr .per-price {
  position: absolute;
  font-size: 12px;
  top: 4px;
  left: 2px;
}

/* order */
.price-advance button,
.vol-advance button {
  font-size: 13px;
  padding: 2px 8px;
  border: 0.5px solid #4e4d62;
  border-radius: 4px;
}

.price-advance button:hover,
.vol-advance button:hover {
  background-color: #17a2b8;
}

.form-trade svg.active path {
  stroke: #f0b90b;
}

ul.list-vendor li.active,
ul.list-vendor li:hover {
  background: linear-gradient(
    180deg,
    var(--color-vendor-from) -10%,
    var(--color-vendor-to) 105%
  );
}

.form-order .form-control {
  background-color: var(--color-bg-input);
  border-color: var(--color-border-input);
  height: 30px;
  border-radius: 2px;
}

.cart-action hr {
  border-top: 1px solid #4e4d62;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-gr-trade {
  background: var(--acc-info-lst-gr-odd);
  border: 0.5px solid #4e4d62;
  border-radius: 5px;
}

.form-gr-trade .form-control {
  border: none !important;
  overflow: hidden;
  padding: 5px 8px 5px 8px;
  line-height: 16px;
  background: transparent;
  font-size: 12px;
}

.btn-paging:active,
.btn-paging:focus,
.form-gr-trade .form-control:active,
.form-gr-trade .form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-gr-trade .btn-minus {
  /* background: var(--color-bg-trade); */
  background: transparent;
  /* border-right: 0.5px solid var(--color-border-trade); */
  border-radius: 5px 0px 0px 5px;
  padding: 2px;
  display: flex;
  justify-content: center;
}

.form-gr-trade .btn-plus {
  /* background: var(--color-bg-trade); */
  background: transparent;
  /* border-left: 0.5px solid var(--color-border-trade); */
  border-radius: 0px 5px 5px 0px;
  padding: 2px;
  display: flex;
  justify-content: center;
}

.form-gr-trade .btn-plus svg,
.form-gr-trade .btn-minus svg {
  color: var(--color-img-tbl);
  font-size: 12px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* checkbox */

.container-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  margin: 0;
  margin-right: 10px;
  white-space: nowrap;
  padding-left: 20px;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_mark {
  position: absolute;
  top: -10px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: var(--cl-nav-link);
  border-radius: 2px;
  border: 1px solid var(--color-border-2);
}

.container-checkbox:hover input ~ .check_mark {
  border-color: var(--color-vendor-from);
}

.check_mark::after {
  content: '';
  position: absolute;
  display: none;
}

.container-checkbox .check_mark::after {
  top: 45%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: solid #27272d;
  border-width: 0 1px 1px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.container-checkbox input:checked ~ .check_mark {
  background: var(--color-vendor-from);
  border-color: var(--color-vendor-from);
}

.container-checkbox input:checked ~ .check_mark::after {
  display: block;
}

/* radio */
.container-radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  padding-left: 28px;
  margin-bottom: 0px;
  font-size: 14px;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.container-radio input:checked ~ .checkmark::after {
  display: block;
}

.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--color-text-nav);
}

.container-radio:hover .checkmark {
  opacity: 0.8;
}
.container-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: solid 3px white;
  transform: translate(-50%, -50%) rotate(45deg);
}

.container-radio input:checked ~ .checkmark {
  border-color: var(--color-vendor-active);
  background: var(--color-vendor-active);
}

.container-radio:hover .checkmark {
  border-color: var(--color-vendor-active);
}

/**------------------------------------------------------------
* bước giá
---------------------------------------------------------------*/

.progress-total .progress {
  background: rgba(240, 161, 182, 0.3);
  opacity: 0.2;
  height: 20px;
  border-radius: 2px;
}

.progress-total .per-vol-sell,
.progress-total .per-vol-buy {
  position: absolute;
  top: 2px;
  color: var(--color-text-half-white);
  font-size: 12px;
}

.progress-total .per-vol-buy {
  left: 10px;
}

.progress-total .per-vol-sell {
  right: 10px;
}

.progress-total .bg-success,
.progress-total .bg-i {
  background: rgba(88, 160, 112, 1);
  height: 20px;
}

.tabs-cash a.active,
.tabs-cash span.active {
  color: var(--color-text-6);
  border-bottom: 1px solid var(--color-text-6);
}

/* đặt lệnh nhanh  */
.panel-trade {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: calc(100vh - 60px);
  width: 460px;
  background-color: var(--color-bg-panel-trade);
  border-radius: 4px;
  padding: 12px;
  backdrop-filter: blur(7px);
  box-shadow: -5px -9px 19.7px 0px #000000a8;
  /* box-shadow: 1px -3px 5.1px 0px #FFFFFF21; */
}

.btn-type-trade {
  width: 98px;
  height: 26px;
  border-radius: 3px;
  font-size: 12px;
  padding: 4px;
}

.btn-type-trade.buy.active {
  background-color: var(--color-bg-buy);
  position: relative;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-type-trade.buy.active::after,
.btn-type-trade.buy.active::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -19px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-left: 19px solid;
  right: -18px;
}

.btn-type-trade.buy.active::after {
  z-index: 2;
  border-left-color: var(--color-bg-buy);
}

.btn-type-trade.sell.active {
  background-color: var(--color-bg-sell);
  color: var(--color-text-base);
  position: relative;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.btn-type-trade.sell.active::after,
.btn-type-trade.sell.active::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -19px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid;
  left: -18px;
}

.btn-type-trade.sell.active::after {
  z-index: 2;
  border-right-color: var(--color-bg-sell);
}

.a-type-trade {
  width: 98px;
  font-size: 12px;
  text-align: center;
  padding: 4px;
}

.input-order.input-symbol {
  display: block;
  color: #495057;
  width: 100%;
  padding: 4px 0.5rem;
  font-weight: 400;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.input-order.input-symbol:focus {
  color: #495057;
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  outline: 0 none;
}

/* .tooltip */
#tooltip-c {
  color: var(--color-text-half-white);
  background-color: var(--color-bg-tooltip);
  border: 0.5px solid var(--color-border-tooltip);
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
  z-index: 1000;
}

.tooltip::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--color-border-tooltip);
}

.tooltip-1 {
  z-index: 1000;
  position: relative;
  color: var(--color-text-half-white);
  background-color: var(--color-bg-tooltip);
  border: 0.5px solid var(--color-border-tooltip);
  border-radius: 4px;
  font-size: 12px;
  padding: 8px;
  display: none;
}

.tooltip-1::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--color-border-tooltip);
  transform: translate(-50%, 0);
}

.tooltip-div:hover .tooltip-1 {
  display: block;
}

.tooltip-2 {
  z-index: 1000;
  position: relative;
  color: #495057;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px;
}

.tooltip-2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
}

.tooltip-2 button {
  background-color: var(--color-bg-primary);
  border-radius: 5px;
  padding: 5px;
}

.tooltip-2 button:hover {
  background-color: var(--color-text-floor);
}

ul.list-price-overview li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 0px;
}

#text-scroll-header {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scroll-text-animation 30s linear infinite;
  -webkit-animation: scroll-text-animation 30s linear infinite;
  animation: scroll-text-animation 30s linear infinite;
}

/* for Firefox */
@-moz-keyframes scroll-text-animation {
  from {
    -moz-transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes scroll-text-animation {
  from {
    -webkit-transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-text-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.form-control::placeholder {
  text-transform: capitalize;
}

.ordStt_PM {
  color: var(--color-stt-1);
}

.ordStt_PC,
.ordStt_P {
  color: var(--color-stt-2);
}

.ordStt_PMX,
.ordStt_PX {
  color: var(--color-stt-3);
}

.paging-type2 svg path {
  stroke: var(--color-text-3);
}

.paging-type2 .disabled svg path {
  stroke: var(--color-border-3);
}

.account-info {
  background-image: url('../img/bg/account-info.png');
  background-position: center;
  background-size: cover;
  height: 320px;
  width: 100%;
}

.slick-slider,
.slick-slider .slick-list,
.slick-slider .slick-list div,
.slick-slider .slick-list img {
  height: 100%;
}

.slick-slider .slick-list .slick-slide div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.slick-slider .slick-list img {
  width: auto !important;
}

.slick-slider .slick-prev {
  left: 5px;
}
.slick-slider .slick-next {
  right: 5px;
}
.slick-slider .slick-dots {
  bottom: 0;
  text-align: left;
}
.slick-dots li button:before {
  color: var(--color-text-white);
}

.slick-dots li.slick-active button:before {
  color: var(--color-vendor-active);
}

.date-full div {
  width: 100%;
}

select.form-gr-trade option {
  background-color: var(--color-natural-2);
}
