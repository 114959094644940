@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-text-up: #00ff57;
    --color-text-down: #d42229;
    --color-text-ref: #ffe70b;
    --color-text-ceil: #a705c2;
    --color-text-floor: #22f2ff;

    --color-text-1: #16181c;
    --color-text-2: #636870;
    --color-text-3: #d9d9d9;
    --color-text-4: #d9d9d9;
    --color-text-6: #ec5e0f;

    --color-stt-1: #00ba34;
    --color-stt-2: #e8b738;
    --color-stt-3: #d42229;

    --color-text-base: #e4e4ed;
    --color-text-white: #ffffff;
    --color-text-dark: #000000;
    --color-text-active: #e1d2b5;
    --color-text-nav: #f1f6fa;
    --color-text-nav-active: #ffffff;
    --color-text-svg-act: #ffffff;

    --color-text-muted: #6f6f6f;

    --color-text-vendor: #bbc7da;
    --color-text-half-white: #818181;
    --color-text-a: #0056b3;

    --color-natural-1: #f5f5f9;
    --color-natural-2: #2d2d40;
    --color-natural-3: #f0f2f8;
    --color-natural-4: #ec5e0f;

    --color-vendor: #3d4044;
    --color-vendor-active: #0F6CBD;
    --color-vendor-hover: #0F6CBD;
    --color-vendor-from: #0F6CBD;
    --color-vendor-to: #0F6CBD;
    --color-nav: #1c192c;
    --color-thumb: #a7a7a7;

    --color-button-success: #18c873;
    --color-button-accent: #0fa779;
    --color-button-accent-hover: #0fa779;
    --color-button-trade: #454c59;

    --color-border: #f4f4f4;
    --color-border-1: #c0c6e2;
    --color-border-2: #d2d6e3;
    --color-border-3: #454c59;
    --color-border-input: #d2d6e3;
    --color-border-tooltip: #d2d6e3;
    --color-border-trade: #4e4d62;
    --color-border-tbl: #575758;

    --color-bg: #e0e3eb;
    --color-bg-header: #ffffff;
    --color-bg-footer: #ffffff;
    --color-bg-panel: #ffffff;
    --color-bg-primary: #ec5e0f;
    --color-bg-tooltip: #ffffff;
    --color-bg-modal: #2d2d40;
    --color-bg-tbl-no-border: #181625;
    --color-bg-total: #353342;
    --color-bg-input: #f0f2f8;
    --color-bg-disable: #2f2d37;
    --color-bg-buy: #0fc786;
    --color-bg-sell: #f5465c;
    --color-bg-trade: #020210;

    --color-bg-tbl-th: #282844;
    --color-bg-tbl-td: #1c1a29;
    --color-bg-tbl-light: #353545;
    --color-bg-tbl-even: #1c1a29;

    --color-bg-tbl-analysis-th: #353342;
    --color-bg-tbl-analysis-td: #1c192c;
    --color-bg-tbl-analysis-light: #373545;
    --color-bg-tbl-analysis-odd: #181625;
    --color-bg-li-light: #0b1c34;
    --color-bg-button-news: #e7f7f2;

    --bg_card_chart: #e0e3eb;

    --color-text-btn-login: #ffffff;
    --color-text-red: #ff334b;
    --color-text-nav-left-active: #0F6CBD;
    --color-text-unit: #5989c0;

    --color-bg-header: #1c192c;
    --color-bg-account-header: #2f2e43;
    --color-bg-dropdown-header: #2f2e43;
    --color-bg-btn-login: #00923f;
    --color-bg-nav-left: #1c192c;
    --color-bg-nav-left-sm: #2f2e43;
    --color-bg-body: #010119;
    --color-bg-nav: #35334e;
    --color-bg-nav-dropdown: #35334e;
    --color-bg-nav-dropdown-active: #514f65;
    --color-bg-panel-trade: #1c192c;
    --color-bg-panel-trade-asset: rgba(40, 43, 47, 0.9);

    --color-bd-hr: #d9d9d9;
    --color-bd-nav-left-active: #0F6CBD;
    --color-bd-nav-active: #d9d9d9;

    --color-shadow-nav-left: #55555540;

    --color-img-header: #d9d9d9;
    --color-radio-header: #ffffff;
    --color-img-tbl: #b6b6b6;
  }

  .light-mode {
    --color-text-up: #00852d;
    --color-text-down: #e0001b;
    --color-text-ref: #e57300;
    --color-text-ceil: #a705c2;
    --color-text-floor: #0074c7;

    --color-text-1: #16181c;
    --color-text-2: #636870;
    --color-text-3: #2f2e43;
    --color-text-4: #1b1b1b;
    --color-text-6: #ec5e0f;

    --color-stt-1: #00ba34;
    --color-stt-2: #e8b738;
    --color-stt-3: #d42229;

    --color-text-base: #2a292e;
    --color-text-white: #2f2e43;
    --color-text-dark: #2f2e43;
    --color-text-active: #e1d2b5;
    --color-text-nav: #2f2e43;
    --color-text-nav-active: #393c43;
    --color-text-svg-act: #ffffff;

    --color-text-muted: #6f6f6f;

    --color-text-vendor: #2f2e43;
    --color-text-half-white: #818181;
    --color-text-a: #0056b3;

    --color-natural-1: #f5f5f9;
    --color-natural-2: #f1f1f1;
    --color-natural-3: #f0f2f8;
    --color-natural-4: #ec5e0f;

    --color-vendor: #3d4044;
    --color-vendor-active: #0F6CBD;
    --color-vendor-hover: #0F6CBD;
    --color-vendor-from: #0F6CBD;
    --color-vendor-to: #0F6CBD;
    --color-nav: #ffffff;
    --color-thumb: #a7a7a7;

    --color-button-success: #18c873;
    --color-button-accent: #0fa779;
    --color-button-accent-hover: #0fa779;
    --color-button-trade: #f2f2f2;

    --color-border: #f4f4f4;
    --color-border-1: #c0c6e2;
    --color-border-2: #b6b6b6;
    --color-border-3: #5e5e5e;
    --color-border-input: #d2d6e3;
    --color-border-tooltip: #d2d6e3;
    --color-border-trade: #4e4d62;
    --color-border-tbl: #969696;

    --color-bg: #e0e3eb;
    --color-bg-header: #ffffff;
    --color-bg-footer: #ffffff;
    --color-bg-panel: #ffffff;
    --color-bg-primary: #ec5e0f;
    --color-bg-tooltip: #ffffff;
    --color-bg-modal: #ffffff;
    --color-bg-tbl-no-border: #181625;
    --color-bg-total: #888888;
    --color-bg-input: #f0f2f8;
    --color-bg-disable: #2f2d37;
    --color-bg-buy: #309781;
    --color-bg-sell: #d42229;
    --color-bg-trade: #020210;

    --color-bg-tbl-th: #f7f7f7;
    --color-bg-tbl-td: #ffffff;
    --color-bg-tbl-light: #efefef;
    --color-bg-tbl-even: #ffffff;
    --color-bg-tbl-analysis-th: #353342;
    --color-bg-tbl-analysis-td: #1c192c;
    --color-bg-tbl-analysis-light: #373545;
    --color-bg-tbl-analysis-odd: #181625;
    --color-bg-li-light: #0b1c34;
    --color-bg-button-news: #e7f7f2;

    --bg_card_chart: #e0e3eb;

    /* Bảng giá */

    --color-text-btn-login: #ffffff;
    --color-text-red: #ff334b;
    --color-text-nav-left-active: #0F6CBD;
    --color-text-unit: #5989c0;
    --color-text-nav: #1b1b1b;
    --color-text-nav-active: #1b1b1b;

    --color-bg-header: #ffffff;
    --color-bg-account-header: #eeeef1;
    --color-bg-dropdown-header: #ffffff;
    --color-bg-btn-login: #00923f;
    --color-bg-nav-left: #ffffff;
    --color-bg-nav-left-sm: #ffffff;
    --color-bg-body: #ffffff;
    --color-bg-nav: #eeeef1;
    --color-bg-nav-dropdown: #eeeef1;
    --color-bg-nav-dropdown-active: #514f65;
    --color-bg-panel-trade: #ffffff;
    --color-bg-panel-trade-asset: rgba(40, 43, 47, 0.9);

    --color-shadow-nav-left: #0000001a;

    --color-bd-hr: #b1b1b1;
    --color-bd-nav-left-active: #0F6CBD;
    --color-bd-nav-active: #d9d9d9;

    --color-img-header: #2f2e43;
    --color-radio-header: #bfbfbf;
    --color-img-tbl: #bfbfbf;
  }

  .dark-mode {
    --color-text-up: #00bf52;
    --color-text-down: #f23645;
    --color-text-ref: #fd8508;
    --color-text-ceil: #fd28fc;
    --color-text-floor: #28c7fd;

    --color-text-1: #16181c;
    --color-text-2: #636870;
    --color-text-3: #d9d9d9;
    --color-text-4: #d9d9d9;
    --color-text-6: #ec5e0f;

    --color-stt-1: #00ba34;
    --color-stt-2: #e8b738;
    --color-stt-3: #f23645;

    --color-text-base: #e4e4ed;
    --color-text-white: #ffffff;
    --color-text-dark: #000000;
    --color-text-active: #e1d2b5;
    --color-text-nav: #f1f6fa;
    --color-text-nav-active: #ffffff;
    --color-text-svg-act: #ffffff;

    --color-text-muted: #6f6f6f;

    --color-text-vendor: #bbc7da;
    --color-text-half-white: #818181;
    --color-text-a: #0056b3;

    --color-natural-1: #f5f5f9;
    --color-natural-2: #202020;
    --color-natural-3: #f0f2f8;
    --color-natural-4: #ec5e0f;

    --color-vendor: #3d4044;
    --color-vendor-active: #0F6CBD;
    --color-vendor-hover: #0F6CBD;
    --color-vendor-from: #0F6CBD;
    --color-vendor-to: #0F6CBD;
    --color-nav: #1c192c;
    --color-thumb: #a7a7a7;

    --color-button-success: #18c873;
    --color-button-accent: #0fa779;
    --color-button-accent-hover: #0fa779;
    --color-button-trade: #404040;

    --color-border: #f4f4f4;
    --color-border-1: #c0c6e2;
    --color-border-2: #d2d6e3;
    --color-border-3: #404040;
    --color-border-input: #d2d6e3;
    --color-border-tooltip: #d2d6e3;
    --color-border-trade: #4e4d62;
    --color-border-tbl: #575758;

    --color-bg: #e0e3eb;
    --color-bg-header: #020506;
    --color-bg-footer: #ffffff;
    --color-bg-panel: #ffffff;
    --color-bg-primary: #ec5e0f;
    --color-bg-tooltip: #ffffff;
    --color-bg-modal: #2d2d40;
    --color-bg-tbl-no-border: #181625;
    --color-bg-total: #353342;
    --color-bg-input: #f0f2f8;
    --color-bg-disable: #2f2d37;
    --color-bg-buy: #0fc786;
    --color-bg-sell: #f5465c;
    --color-bg-trade: #020210;

    --color-bg-tbl-th: #0e0e0e;
    --color-bg-tbl-td: #0e0e0e;
    --color-bg-tbl-light: #212121;
    --color-bg-tbl-even: #0e0e0e;

    --color-bg-tbl-analysis-th: #353342;
    --color-bg-tbl-analysis-td: #1c192c;
    --color-bg-tbl-analysis-light: #373545;
    --color-bg-tbl-analysis-odd: #181625;
    --color-bg-li-light: #0b1c34;
    --color-bg-button-news: #e7f7f2;

    --bg_card_chart: #e0e3eb;

    --color-text-btn-login: #ffffff;
    --color-text-red: #ff334b;
    --color-text-nav-left-active: #0F6CBD;
    --color-text-unit: #5989c0;

    --color-bg-account-header: #202020;
    --color-bg-dropdown-header: #202020;
    --color-bg-btn-login: #00923f;
    --color-bg-nav-left: #141414;
    --color-bg-nav-left-sm: #2f2e43;
    --color-bg-body: #020506;
    --color-bg-nav: #020506;
    --color-bg-nav-dropdown: #202020;
    --color-bg-nav-dropdown-active: rgba(191, 191, 191, 0.2);
    --color-bg-panel-trade: #202020;
    --color-bg-panel-trade-asset: rgba(40, 43, 47, 0.9);

    --color-bd-hr: #d9d9d9;
    --color-bd-nav-left-active: #0F6CBD;
    --color-bd-nav-active: rgba(217, 217, 217, 0.4);

    --color-shadow-nav-left: #55555540;

    --color-img-header: #d9d9d9;
    --color-radio-header: #ffffff;
    --color-img-tbl: #b6b6b6;
  }
}
