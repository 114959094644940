.nav-account.nav-account.nav-md {
  min-height: -webkit-fill-available;
  flex-direction: column;
  padding: 0;
  box-shadow: 3px 0px 5.300000190734863px 0px #00000026;
  background: var(--color-bg-nav-left);
}

.nav-account.nav-account.nav-sm {
  flex-direction: column;
  padding: 0;
  box-shadow: 0px -4px 4px 0px var(--color-shadow-nav-left);
  background: var(--color-bg-nav-left-sm);
  min-height: 70px;
  max-height: 140px;
}

.nav-account.nav_full {
  padding: 0;
}

.nav-account ul li a {
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--color-text-nav);
  height: 70px;
  align-items: center;
  cursor: pointer;
}

.nav-account ul li a svg {
  height: 24px;
  width: 24px;
  margin-bottom: 4px;
}

.nav-account ul li a.disabled {
  color: #6c757d;
}

.nav-account.nav-md ul li a:hover,
.nav-account.nav-md ul li a.active {
  background: linear-gradient(
    90deg,
    rgba(15, 188, 44, 0) 0%,
    rgba(15, 188, 44, 0.1) 100%
  );
  border-right: 2px solid var(--color-bd-nav-left-active);
  color: var(--color-text-nav-left-active);
}

.nav-account.nav-sm ul li a:hover,
.nav-account.nav-sm ul li a.active {
  color: #0F6CBD;
}

.nav-account ul li a svg path:not([stroke]) {
  fill: var(--color-text-nav);
}

.nav-account ul li a svg path[stroke] {
  stroke: var(--color-text-nav);
}

.nav-account ul li a.disabled svg path:not(:stroke) {
  fill: #6c757d;
}

.nav-account ul li a.disabled svg path[stroke] {
  stroke: #6c757d;
}

.nav-account ul li a:hover svg path:not([stroke]),
.nav-account ul li a.active svg path:not([stroke]) {
  fill: #0F6CBD;
}

.nav-account ul li a:hover svg path[stroke],
.nav-account ul li a.active svg path[stroke] {
  stroke: #0F6CBD;
}

.nav-account ul li.active a.has-content:first-child {
  background: var(--color-vendor);
  color: var(--color-text-nav-left-active);
}

.nav-account ul li.active a.has-content:first-child svg path {
  stroke: var(--color-text-svg-act);
}

.nav-account.nav_minimize {
  padding-top: 15px;
}

.nav-account.nav_minimize ul li {
  justify-content: center;
}

.nav-account.nav_minimize ul li a {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.nav-account.nav_minimize ul li a:hover {
  border-left-color: var(--color-text-svg-act);
  color: var(--color-text-svg-act);
}

.nav-account.nav_minimize ul li a.active {
  color: var(--color-text-svg-act);
}

.nav-account.nav_minimize ul li.p-0 {
  position: relative;
}

.nav-account.nav_minimize ul li svg {
  margin-right: 0;
}

.nav-account ul li .ps__rail-y {
  right: 10px !important;
}

.nav-account.nav_minimize ul li.p-0:hover ul.sub-menu {
  display: block;
}

.nav-account.nav_minimize ul li.p-0 ul.sub-menu {
  position: absolute;
  top: 0;
  left: 58px;
  width: 220px !important;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  background: #213330;
  z-index: 5;
  display: none;
}

.nav-account.nav_minimize ul li.p-0 ul.sub-menu li {
  justify-content: flex-start;
  border: 2px solid #2e4642;
  border-width: 0 0 1px 5px;
}

.nav-account ul.sub-sub-menu li:hover a,
.nav-account ul.sub-menu li:hover a {
  color: var(--color-text-base);
}

.nav-account .nav-logout {
  padding: 0 16px;
  color: var(--color-text-nav);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 12px 16px;
  margin: 0;
  cursor: pointer;
  justify-content: center;
}

.nav-account.nav_minimize .nav-logout {
  text-align: center;
}

.nav-account .nav-logout:hover {
  background: var(--color-natural-1);
  color: var(--color-text-nav-left-active);
  border-radius: 12px;
}

.nav-account .nav-logout:hover svg path {
  stroke: var(--color-text-nav-left-active);
}

.nav-account .nav-logout span {
  padding-left: 16px;
}

.nav-account .nav-logout svg path {
  stroke: var(--color-text-nav);
}

.navbar-priceboard .dropdown {
  position: relative;
}

.navbar-priceboard .nav-item {
  height: 26px;
  border-radius: 3px;
  background-color: var(--color-bg-nav);
  color: var(--color-text-nav);
  padding: 5px 7px;
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.navbar-priceboard .nav-link {
  color: var(--color-text-nav) !important;
  cursor: pointer;
  display: flex;
  align-items: center;
}


.navbar-priceboard .nav-item .dropdown-menu .nav-link:hover{
  color: #ffffff !important;
}

.navbar-priceboard .nav-link svg {
  width: 10px;
  margin-left: 7px;
}

.navbar-priceboard .nav-link path {
  fill: var(--color-img-header);
}

.navbar-priceboard .dropdown:hover .dropdown-menu {
  display: block;
}

.navbar-priceboard .dropdown-menu {
  background-color: var(--color-bg-nav-dropdown);
  color: var(--color-text-nav);
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 8px;
}

.navbar-priceboard .dropdown-menu:hover {
  display: block;
}

.navbar-priceboard .dropdown-menu .nav-link {
  margin-bottom: 2px;
  padding: 4px 6px;
}

.navbar-priceboard .active.nav-item {
  border: 1px solid var(--color-bd-nav-active);
}

.navbar-priceboard .nav-item.active a.dropdown-toggle,
.navbar-priceboard .nav-item.active a.nav-cw {
  font-style: normal;
  color: var(--color-text-nav-active) !important;
}

.navbar-priceboard .dropdown-menu .nav-link:hover {
  background: var(--color-bg-nav-dropdown-active);
  border-radius: 4px;
}

/* .navbar-priceboard .dropdown-toggle::after {
  vertical-align: 0.1em;
  border-top: 0.45em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
  display: inline-block;
  margin-left: 0.255em;
  content: '';
} */

.navbar-priceboard .nav-item-drop.active {
  background-color: var(--color-bg-primary);
  color: var(--color-text-base) !important;
}

.navbar-priceboard .nav-item-drop.active .nav-link {
  background-color: var(--color-bg-primary);
  color: var(--color-text-base) !important;
}

.navbar-priceboard .nav-item-drop .nav-link {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-drop {
  font-size: 12px;
  padding: 1px 0.5rem !important;
  height: 22px;
  color: #fff;
  background: var(--color-bg-primary);
  border-radius: 2px;
}

.btn-drop:hover {
  background: #6c757d;
  color: var(--color-text-base);
}

.field-change-catalog {
  font-size: 12px;
  height: 22px;
  border: none;
  color: #000;
  width: 150px;
}

.field-change-catalog:focus {
  border: none;
  outline: none;
}

.form-type-index span {
  padding: 3px 4px;
}

.form-type-index span.active {
  border-radius: 4px;
  background-color: var(--color-bg-primary);
}

.form-type-index span.theme svg path {
  fill: var(--color-text-half-white);
}

.form-type-index span.theme.active svg path {
  fill: var(--color-text-base);
}

.nav-shadow-full {
  box-shadow: 0px 2px 8px 0px #00000026;
}

/* nav trade */

.nav-trade {
  height: 26px;
  padding-top: 8px;
  border-top: 0.5px solid var(--color-border-3);
  color: var(--color-thumb);
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 13px;
}
